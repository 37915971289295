



























































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import Tinymce from "@/components/Editor/Tinymce.vue";
import Datepicker from "@/components/Datepicker.vue";
import TimePicker from "@/components/TimePicker.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, requiredIf } from "vuelidate/lib/validators";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import SaveButton from "@/components/Button/SaveButton.vue";
import IBroadcast from "@/interfaces/IBtroadcast";
import { IEmailTemplate } from "@/interfaces/IEmailTemplate";

@Component({
  components: {
    SaveButton,
    FscSimpleCard,
    FscMultiselect,
    AbortButton,
    Tinymce,
    Datepicker,
    TimePicker,
    Validation,
  },
})
export default class BroadcastForm extends Vue {
  public name = "BroadcastForm";
  @Prop({ type: Boolean, default: false })
  public formIsNew?: any;

  @Prop({ type: Array, default: () => [] })
  public recipientGroups!: Array<any>;

  @Prop({ type: Object, default: () => ({}) })
  public getSelectedBroadcast: any;

  @Prop({ type: Array, default: () => [] })
  public templates: any;

  public broadcast: any = {} as IBroadcast;

  public selectedRecipientGroup: any = null;
  public selectedEntry: any = null;
  public recipientOptionConst: any = {
    theoryCourse: 4,
    theoryExam: 7,
    practicalExam: 6,
  };

  public startTime = "00:00";
  public endTime = "00:00";

  public template: any = {} as IEmailTemplate;
  public emptyTemplate: IEmailTemplate | any = {
    id: 0,
    name: "",
    subject: "",
    templateCategoryId: 0,
  };

  public get isAdditionalEntryRequired() {
    return this.selectedRecipientGroup?.entryInputRequired || false;
  }

  public get getRecipientGroups() {
    const recipientGroups = this.recipientGroups.map((group: any) => {
      if (group && group.entryInputRequired && group?.entries && group?.entries?.length > 0 && group?.label?.toLowerCase().indexOf("datum") > -1) {
        group?.entries.reverse();
        return group;
      } else {
        return group;
      }
    });
    return recipientGroups || [];
  }

  public setData() {
    this.broadcast = this.getSelectedBroadcast;

    this.broadcast.startDate = this.getSelectedBroadcast.publicationStart ? this.getSelectedBroadcast.publicationStart.split("T")[0] : null;
    this.startTime = this.getSelectedBroadcast.publicationStart ? this.getSelectedBroadcast.publicationStart.split("T")[1] : null;

    this.broadcast.endDate = this.getSelectedBroadcast.publicationEnd ? this.getSelectedBroadcast.publicationEnd.split("T")[0] : null;
    this.endTime = this.getSelectedBroadcast.publicationEnd ? this.getSelectedBroadcast.publicationEnd.split("T")[1] : null;

    this.selectedRecipientGroup = this.recipientGroups?.find((recipient: any) => recipient.id == this.broadcast.recipientsGroupType);
    this.selectedEntry = this.entryOptions.find((fastlane: any) => fastlane.id === this.broadcast.groupEntryId);
  }

  public getPreselectedRecipientOption({ entryId, type }: any) {
    const selectedRecipientId = type && entryId ? { recGroupId: this.recipientOptionConst[type], entryId } : 0;
    return selectedRecipientId;
  }

  public mounted() {
    this.$root.$on("broadcast-selection-change", ({ formIsNew, entryId, type }: any) => {
      this.$nextTick(() => {
        if (!entryId) {
          this.setData();
        }
        if (formIsNew) {
          this.selectedEntry = {};
          this.selectedRecipientGroup = {};
          this.startTime = "00:00";
          this.endTime = "00:00";
          if (entryId && type) {
            const preselectedOption = this.getPreselectedRecipientOption({ entryId, type });
            if (preselectedOption === 0) return;
            this.$v.$reset();
            this.template = _.cloneDeep(this.emptyTemplate);
            this.broadcast = {} as IBroadcast;
            setTimeout(() => {
              this.broadcast = { ..._.cloneDeep(this.broadcast), body: "", subject: "" };
              this.selectedRecipientGroup = this.getRecipientGroups?.find((recipient: any) => recipient.id == preselectedOption.recGroupId);
              this.selectedEntry = this.entryOptions?.find((fastlane: any) => fastlane.id === preselectedOption.entryId);
            }, 400);

            this.$watch(
              () => [this.getRecipientGroups, this.entryOptions],
              () => {
                if (preselectedOption.entryId && (!this.selectedRecipientGroup || Object.keys(this.selectedRecipientGroup).length === 0)) {
                  this.selectedRecipientGroup = this.getRecipientGroups?.find((recipient: any) => recipient.id == preselectedOption.recGroupId);
                  this.selectedEntry = this.entryOptions?.find((fastlane: any) => fastlane.id === preselectedOption.entryId);
                }
              },
              { immediate: true, deep: true }
            );
          }
        }
        return;
      });
    });
    if (!this.formIsNew) {
      this.setData();
    } else {
      this.selectedRecipientGroup = {};
      this.broadcast = {};
    }
  }

  public onSelectRecipient(e: any): void {
    this.selectedRecipientGroup = e;
    this.broadcast.recipientGroupType = e.id;
    this.selectedEntry = e.entries?.find((entry: any) => entry.id === this.broadcast.groupEntryId);
  }

  public onSelectEntry(e: any): void {
    this.selectedEntry = e;
  }

  public onAbort() {
    this.$emit("close");
  }

  public formatPublicationDates() {
    this.broadcast.publicationStart = this.broadcast.startDate ? this.broadcast.startDate + "T" + (this.startTime ? this.startTime : "00:00") + ":00Z" : null;
    this.broadcast.publicationEnd = this.broadcast.endDate ? this.broadcast.endDate + "T" + (this.endTime ? this.endTime : "00:00") + ":00Z" : null;
  }

  public onBroadcastSave(): void {
    this.formatPublicationDates();

    this.broadcast.recipientsGroupType = this.selectedRecipientGroup.id;
    if (this.selectedEntry && this.selectedEntry.id) {
      this.broadcast.groupEntryId = this.selectedEntry.id;
    }

    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    delete this.broadcast.startDate;
    delete this.broadcast.endDate;
    this.$emit("confirm-data-set", {
      broadcast: this.broadcast,
      editMode: !this.formIsNew,
    });
    this.$v.$reset();
    this.broadcast = {};
    this.selectedEntry = null;
    this.selectedRecipientGroup = null;
  }

  @Watch("getSelectedBroadcast", { immediate: true, deep: true })
  public onSelectedBroadcastChange(broadcast: any) {
    this.$nextTick(() => {
      this.broadcast = _.cloneDeep(broadcast);
      this.template = _.cloneDeep(this.emptyTemplate);
    });
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      broadcast: {
        name: { required },
        subject: { required },
        body: { required },
      },
      selectedRecipientGroup: { required },
      selectedEntry: {
        required: requiredIf(() => {
          return this.isAdditionalEntryRequired;
        }),
      },
    };
  }

  public get entryOptions() {
    return this.selectedRecipientGroup?.entries || [];
  }

  public get selectedEntryLabel() {
    return this.selectedRecipientGroup?.label || "";
  }

  public onSelectTemplate(e: any) {
    if (e) {
      this.broadcast.body = this.template.body;
      this.broadcast.subject = this.template.subject;
    }
  }
}
