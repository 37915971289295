



























import { Vue, Component, Prop } from "vue-property-decorator";
import BroadcastCardItem from "@/views/Communication/BroadcastElements/BroadcastCardItem.vue";

@Component({
  components: { BroadcastCardItem },
})
export default class BroadcastList extends Vue {
  public name = "BroadcastList";
  public selectedItem?: any | null = {};

  @Prop()
  public items!: Array<any>;

  @Prop()
  public isLoading!: any;

  public onSelect(e: any) {
    this.selectedItem = e;
    this.$emit("click", e);
  }
}
