




































































































import { Component, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import FuncMixins from "@/mixins/FuncMixins";
import BroadcastList from "@/views/Communication/BroadcastElements/BroadcastList.vue";
import Actions from "@/components/Actions.vue";
import IBroadcast from "@/interfaces/IBtroadcast";
import BroadcastForm from "@/views/Communication/BroadcastElements/BroadcastForm.vue";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import eventBus from "@/plugins/EventBus";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import axios from "@/utils/axios";
import SettingsButton from "@/components/Button/SettingsButton.vue";
import _ from "lodash";

const BroadcastModule = namespace("broadcast");
const BroadcastFilterModule = namespace("broadcast/localFilter");
const BroadcastTemplatesModule = namespace("broadcast-templates");

@Component({
  components: {
    Actions,
    FscPageHeader,
    FilterAndSearch,
    CreateButton,
    ArchiveButton,
    ArchiveModal,
    BroadcastList,
    BroadcastForm,
    AbortButton,
    SaveButton,
    SettingsButton,
  },
})
export default class Broadcast extends mixins(ModalMixin, FuncMixins) {
  public name = "Broadcast";

  @BroadcastFilterModule.State("filter")
  public broadcastsLocalFilter: any;

  @BroadcastFilterModule.Mutation("SET_LOCAL_FILTER")
  public setBroadcastsLocalFilter!: (payload: Record<any, any>) => void;

  /** store Broadcasts actions start*/
  @BroadcastModule.Action("findAll")
  private findAllBroadcasts!: (options: ICrudOptions) => Promise<void>;

  @BroadcastModule.Action("create")
  private broadcastCreate!: (options: ICrudOptions) => Promise<void>;

  @BroadcastModule.Action("update")
  private broadcastUpdate!: (options: ICrudOptions) => Promise<void>;

  @BroadcastModule.Action("archive")
  private broadcastArchive!: any;

  /** store Broadcasts getters start **/
  @BroadcastModule.Getter("getDataList")
  private getBroadcasts!: any;

  @BroadcastModule.Getter("getIsLoading")
  private getIsLoading!: any;

  @BroadcastModule.Getter("getSuccess")
  private getSuccess!: any;

  @BroadcastTemplatesModule.Getter("getTemplates")
  private getBroadcastTemplates: any;

  @BroadcastTemplatesModule.Action("getTemplatesAllAction")
  private getBroadcastTemplatesAction: any;

  public selectedBroadcast: any | null = {};
  public formIsNew = false;
  public formOpen = false;
  public emptyBroadcast = {
    id: 0,
    subject: "",
    body: "",
    name: "",
    publicationStart: "",
    publicationEnd: "",
    recipientsGroupType: 0,
    groupEntryId: 0,
    ready: false,
    archived: false,
    active: false,
    recipients: 0,
  };

  public broadcastStatuses = [
    {
      name: this.$t("todo.all"),
      value: "all",
    },
    {
      name: this.$tc("general.active", 1),
      value: "active",
    },
    {
      name: this.$t("sidebar.broadcast_ready"),
      value: "finished",
    },
    {
      name: this.$tc("general.archived", 1),
      value: "archived",
    },
    {
      name: this.$t("sidebar.broadcast_prepared"),
      value: "prepared",
    },
  ];
  public broadcastSelectedStatus: any | null = {
    name: this.$tc("general.active", 1),
    value: "active",
  };
  public groups = [];
  public currentId = 0;
  public actionType = "";
  public formMessage = "";
  public currentBroadcast: any = null;

  private searchTerm = "";
  public get formIsOpen() {
    return this.formOpen;
  }

  private onSearch(value: string) {
    this.debouncer(() => {
      this.searchTerm = value;
      this.saveLocalFilter();
    });
  }

  private onChangeBroadcastStatus() {
    this.saveLocalFilter();
  }

  public get isLoading() {
    return this.getIsLoading || false;
  }

  public get broadcasts(): Array<IBroadcast> {
    return this.getBroadcasts.broadcasts;
  }

  public get rightSectionTitle() {
    let title = "";
    if (this.formOpen && this.formIsNew) {
      title = this.$tc("sidebar.new_broadcast_title");
    } else if (this.formOpen && !this.formIsNew) {
      title = this.selectedBroadcast.name;
    }
    return title;
  }

  private async fetchBroadCasts() {
    await this.findAllBroadcasts({ resource: "/broadcasts" });

    if (this.getBroadcasts) {
      this.groups = this.getBroadcasts.groups.map((group: any) => ({
        id: group.group.id,
        name: group.group.name,
        label: group.group.label,
        entries: group.entries,
        entryInputRequired: group.entryInputRequired,
      }));
    }
  }

  public mounted() {
    eventBus.$on(["run-broadcast", "stop-broadcast"], (broadcast: any) => {
      this.currentBroadcast = broadcast;
      this.currentId = broadcast.id;
      this.actionType = broadcast.ready ? "stop" : "run";
      this.formMessage = this.actionType === "run" ? this.$tc("question.broadcast_run") : this.$tc("question.broadcast_stop");
      this.$bvModal.show("run-broadcast-modal");
    });
    this.onChangeBroadcastStatus();
    this.fetchBroadCasts();
    this.getBroadcastTemplatesAction();

    if (this.broadcastsLocalFilter instanceof Object) {
      const { searchTerm, broadcastStatus } = this.broadcastsLocalFilter;
      this.broadcastSelectedStatus = broadcastStatus;
      this.searchTerm = searchTerm;
    } else {
      this.broadcastSelectedStatus = this.broadcastStatuses[0];
    }
  }

  private archiveAction(e: any) {
    this.broadcastArchive({
      id: this.selectedBroadcast.id,
      archived: !this.selectedBroadcast.archived,
    });
    this.$bvModal.hide("broadcast-archive-modal");
  }

  private createBroadcast() {
    this.formIsNew = true;
    this.formOpen = true;
    this.selectedBroadcast = _.cloneDeep(this.emptyBroadcast);
    this.$root.$emit("broadcast-selection-change", { formIsNew: true });
  }

  private showArchiveModal() {
    if (!this.selectedBroadcast || !this.selectedBroadcast?.id) return;
    this.$bvModal.show("broadcast-archive-modal");
  }

  private async onRunBroadcast(): Promise<void> {
    if (this.actionType === "run") {
      await this.broadcastChangeReadyState(this.currentId, true);
    } else if (this.actionType === "stop") {
      await this.broadcastChangeReadyState(this.currentId, false);
    }
  }

  private broadcastChangeReadyState(id: number, flagReady: any) {
    if (!id) return;
    this.$bvModal.hide("run-broadcast-modal");
    const url = "broadcasts/" + id + "/change-ready-state?ready=" + flagReady;
    axios
      .put(url)
      .then(({ status }: any) => {
        if (status !== 200) return;
        this.findAllBroadcasts({ resource: "broadcasts" });
      })
      .catch((e) => console.error(e));
  }

  private saveLocalFilter() {
    this.setBroadcastsLocalFilter({ broadcastStatus: this.broadcastSelectedStatus, searchTerm: this.searchTerm });
  }

  public onSelectBroadcast(e: any) {
    if (!e) return;
    this.formIsNew = false;
    this.formOpen = true;
    this.selectedBroadcast = Object.assign(e, {});
    this.$root.$emit("broadcast-selection-change", { formIsNew: false });
  }

  public async onSave(data: any): Promise<void> {
    this.formOpen = false;
    const { broadcast } = data;
    if (!this.formIsNew) {
      const updatedBroadcast = {
        body: broadcast.body,
        groupEntryId: broadcast.groupEntryId,
        id: broadcast.id,
        recipients: broadcast.recipients,
        recipientsGroupType: broadcast.recipientsGroupType,
        subject: broadcast.subject,
        publicationStart: broadcast.publicationStart,
        publicationEnd: broadcast.publicationEnd,
        name: broadcast.name,
      };
      await this.broadcastUpdate({
        resource: "broadcasts",
        data: { ...updatedBroadcast },
        id: this.selectedBroadcast.id,
      });
    } else {
      const newBroadcast = {
        body: broadcast.body,
        subject: broadcast.subject,
        name: broadcast.name,
        publicationEnd: broadcast.publicationEnd,
        publicationStart: broadcast.publicationStart,
        recipientsGroupType: broadcast.recipientsGroupType,
        groupEntryId: broadcast.groupEntryId,
      };
      await this.broadcastCreate({
        resource: "broadcasts",
        data: newBroadcast,
      });
    }

    if (this.getSuccess) {
      this.formOpen = false;
      this.selectedBroadcast = null;
      await this.findAllBroadcasts({ resource: "broadcasts" });
    }
  }

  public onClose() {
    this.formOpen = false;
    this.formIsNew = true;
    this.selectedBroadcast = null;
  }

  public get filteredSortedBroadcasts() {
    if (this.broadcasts && this.broadcasts.length > 0) {
      const key = this.broadcastSelectedStatus.value;

      let localBroadcasts = this.broadcasts;

      if (key !== "all") {
        localBroadcasts = localBroadcasts.filter((broadcast: any) => broadcast[key] === true);
      }

      localBroadcasts = localBroadcasts.filter((broadcast: any) => broadcast.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1);

      return localBroadcasts.sort(function (a: any, b: any) {
        if (!a.publicationStart && !b.publicationStart) return -1;
        return a.publicationStart < b.publicationStart ? 1 : -1;
      });
    }
    return this.broadcasts;
  }

  public onOpenTemplateSettings() {
    this.$router.push({ name: "BroadcastTemplates" });
  }
  @Watch("$route", { immediate: true, deep: true })
  public onRouteChange(newRoute: any, oldRoute: any) {
    if ((newRoute?.name === "Broadcast" && oldRoute?.name === "Theory") || (newRoute?.name === "Broadcast" && newRoute?.query?.newForm == "true")) {
      this.formIsNew = true;
      this.formOpen = true;
      this.selectedBroadcast = _.cloneDeep(this.emptyBroadcast);
    }
  }
}
