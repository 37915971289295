



























import { Vue, Component, Prop } from "vue-property-decorator";
import eventBus from "@/plugins/EventBus";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: {
    FscSimpleCard,
  },
})
export default class BroadcastCardItem extends Vue {
  public name = "BroadcastCardItem";

  @Prop()
  public item?: any;

  public emitRunBroadcastEvent(item: any) {
    if (!item.ready) {
      eventBus.$emit("run-broadcast", item);
    } else {
      eventBus.$emit("stop-broadcast", item);
    }
  }
}
